<template>
  <div class="error-detail-informations">
    <h5>{{ $t('error-detail.informations.heading') }}</h5>
    <ul class="informations-list">
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.createdBy') }}
        </span>
        <div class="value created-by">
          <span class="circle">
            {{ detailInformations.author.firstName[0] }}{{ detailInformations.author.lastName[0] }}
          </span>
          <div class="info-data">
            <span class="fullname">
              {{ detailInformations.author.firstName }} {{ detailInformations.author.lastName }}
            </span>
            <span class="email">
              {{ detailInformations.author.email }}
            </span>
          </div>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.description') }}
        </span>
        <div class="value">
          <p class="data">
            {{ detailInformations.description }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.createdAt') }}
        </span>
        <div class="value">
          <p class="data">
            {{ dateHelper(detailInformations.createdAt) }} {{ timeHelper(detailInformations.createdAt) }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.machineStatus') }}
        </span>
        <div
          v-if="detailInformations.machineStatus === 'STOPPED'"
          class="value"
        >
          <img
            :src="CardIcon1"
            :alt="$t('error-report.machines-report.stopped')"
            class="status-icon"
          >
          <p class="data stopped">
            {{ $t('error-report.machines-report.stopped') }}
          </p>
        </div>
        <div
          v-if="detailInformations.machineStatus === 'LIMITED'"
          class="value"
        >
          <img
            :src="CardIcon2"
            :alt="$t('error-report.machines-report.limited')"
            class="status-icon"
          >
          <p class="data limited">
            {{ $t('error-report.machines-report.limited') }}
          </p>
        </div>
        <div
          v-if="detailInformations.machineStatus === 'POOR_QUALITY'"
          class="value"
        >
          <img
            :src="CardIcon3"
            :alt="$t('error-report.machines-report.poorQuality')"
            class="status-icon"
          >
          <p class="data poor-quality">
            {{ $t('error-report.machines-report.poorQuality') }}
          </p>
        </div>
        <div
          v-if="detailInformations.machineStatus === 'PLANNED_MAINTENANCE'"
          class="value"
        >
          <img
            :src="CardIcon4"
            :alt="$t('error-report.machines-report.plannedMaintenance')"
            class="status-icon"
          >
          <p class="data planned-maintenance">
            {{ $t('error-report.machines-report.plannedMaintenance') }}
          </p>
        </div>
        <div
          v-if="detailInformations.machineStatus === 'RUNNING'"
          class="value"
        >
          <img
            :src="CardIcon5"
            :alt="$t('error-report.machines-report.running')"
            class="status-icon running"
          >
          <p class="data running">
            {{ $t('error-report.machines-report.running') }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.category') }}
        </span>
        <div class="value">
          <p class="data">
            {{ $t('error-detail.informations.categoryValue') }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.place') }}
        </span>
        <div class="value">
          <p class="data">
            {{ detailInformations.machine.hall.name }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.type') }}
        </span>
        <div class="value">
          <p
            v-if="detailInformations.machine.type === 'MACHINE'"
            class="data"
          >
            {{ $t('error-report.machines-report.machine') }}
          </p>
          <p
            v-if="detailInformations.machine.type === 'BUILDING_EQUIPMENT'"
            class="data"
          >
            {{ $t('error-report.machines-report.building') }}
          </p>
          <p
            v-if="detailInformations.machine.type === 'OTHER_EQUIPMENT'"
            class="data"
          >
            {{ $t('error-report.machines-report.devices') }}
          </p>
          <p
            v-if="detailInformations.machine.type === 'WORKSHOP'"
            class="data"
          >
            {{ $t('error-report.machines-report.workshop') }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.machineType') }}
        </span>
        <div class="value">
          <p class="data">
            {{ detailInformations.machine.name }}
          </p>
        </div>
      </li>
      <li class="information">
        <span class="information-title">
          {{ $t('error-detail.informations.errorType') }}
        </span>
        <div class="value">
          <p class="data">
            {{ detailInformations.errorCategory.name }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'

export default {
  name: 'ErrorDetailInformations',
  props: {
    detailInformations: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5
    }
  },
  methods: {
    dateHelper,
    timeHelper
  }
}
</script>

<style lang="scss" scoped>
div.error-detail-informations {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $primary-white;
  padding: 34px 32px;

  h5 {
    margin-bottom: 26px;
  }

  ul.informations-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: 100%;

    li.information {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $primary-lightgrey;
      padding: 22px 0;

      &:last-child {
        border-bottom: none;
      }

      span.information-title {
        width: 200px;
        margin-right: 16px;
        font-size: 14px;
        font-weight: 500;
        color: $primary-grey;
      }

      div.value {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 353px;

        &.created-by {
          display: flex;
          align-items: center;

          span.circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: $primary-white;
            background-color: $status-green;
            margin-right: 12px;
          }

          div.info-data {
            display: flex;
            flex-direction: column;
            font-size: 14px;

            span.fullname {
              font-weight: 500;
            }

            span.email {
              color: $primary-grey;
            }
          }
        }

        img.status-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }

      p.data {
        font-size: 14px;
        color: $primary-black;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: 0.25px;
        word-break: break-word;
        margin-bottom: 0;

        &.stopped {
          color: $primary-red;
        }

        &.limited,
        &.poor-quality {
          color: $status-yellow;
        }

        &.planned-maintenance,
        &.running {
          color: $status-green;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-detail-informations {
    padding: 30px 24px 24px 24px;

    h5 {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-weight: 500;
      margin-bottom: 22px;
    }

    ul.informations-list {
      li.information {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0;

        span.information-title {
          width: 100%;
          margin: 0 0 4px 0;
          letter-spacing: 0.5px;
        }

        div.value {
          max-width: 100%;

          p.data {
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
</style>

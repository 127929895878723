<template>
  <div class="error-detail-history">
    <h5>{{ $t('error-detail.history.heading') }}</h5>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :hide-default-footer="windowWidth <= 960 ? true : false"
      :disable-pagination="windowWidth <= 960 ? true : false"
      mobile-breakpoint="960"
    >
      <!-- eslint-disable -->
      <template v-slot:header.createdAt="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.status="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.errorStatus="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="created-at">
          {{ dateHelper(item.createdAt) }} {{ timeHelper(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div
          v-if="item.machineStatus === 'STOPPED'"
          class="status"
        >
          <img
            :src="CardIcon1"
            :alt="$t('error-report.machines-report.stopped')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.machineStatus === 'LIMITED'"
          class="status"
        >
          <img
            :src="CardIcon2"
            :alt="$t('error-report.machines-report.limited')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.machineStatus === 'POOR_QUALITY'"
          class="status"
        >
          <img
            :src="CardIcon3"
            :alt="$t('error-report.machines-report.poorQuality')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.machineStatus === 'PLANNED_MAINTENANCE'"
          class="status"
        >
          <img
            :src="CardIcon4"
            :alt="$t('error-report.machines-report.plannedMaintenance')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.machineStatus === 'RUNNING'"
          class="status"
        >
          <img
            :src="CardIcon5"
            :alt="$t('error-report.machines-report.running')"
            class="status-icon"
          >
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="name">
          {{ item.errorCategory.name }}
        </div>
      </template>
      <template v-slot:item.errorStatus="{ item }">
        <div class="error-status">
          <div
            v-if="item.mainTask == null"
            class="nobody"
          >
            {{ $t('dashboard.error-table.table.unassigned') }}
          </div>
          <div
            v-else
            class="assigned"
          >
            <img
              :src="AssignedIcon"
              alt="Icon"
              class="icon"
            >
            {{ item.mainTask.user.fullName }}
          </div>
        </div>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
  </div>
</template>

<script>
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import AssignedIcon from '@/assets/icons/assigned_icon.svg'
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'

export default {
  name: 'ErrorDetailHistory',
  props: {
    machineId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      AssignedIcon,
      headers: [
        { text: this.$t('dashboard.error-table.table.headers.dateTime'), value: 'createdAt' },
        { text: this.$t('dashboard.error-table.table.headers.status'), value: 'status' },
        { text: this.$t('dashboard.error-table.table.headers.type'), value: 'name' },
        { text: this.$t('dashboard.error-table.table.headers.jobStatus'), value: 'errorStatus' }
      ],
      windowWidth: 0,
      tableData: []
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getTableData()
  },
  methods: {
    dateHelper,
    timeHelper,
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    async getTableData () {
      const { data } = await this.$axios.get('machine-errors/?select=*,mainTask(user(*))')
      for (let i = 0; i < data.length; i++) {
        if (data[i].finishedAt && data[i].machineId === this.machineId) {
          this.tableData.push(data[i])
        }
      }
      console.log(this.tableData)
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-detail-history {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $primary-white;
  padding: 34px 32px;

  h5 {
    margin-bottom: 26px;
  }

  div.created-at,
  div.status,
  div.name,
  div.error-status {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 56px;
    color: $primary-black;

    img.status-icon {
      width: 24px;
      height: 24px;
    }

    div.nobody {
      display: flex;
      align-items: center;
      background-color: #F5F5F5;
      height: 24px;
      padding: 2px 24px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $primary-black;
    }

    div.assigned {
      display: flex;
      align-items: center;
      background-color: $status-green;
      // height: 24px;
      padding: 2px 12px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $primary-white;

      img.icon {
        margin-right: 6px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-detail-history {
    padding: 30px 24px 24px 24px;

    h5 {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-weight: 500;
      margin-bottom: 22px;
    }
  }
}
</style>

<template>
  <div
    class="end-task-modal-overlay"
    @click.stop="$emit('close-modal')"
  >
    <div
      class="modal"
      @click.stop
    >
      <div class="modal-header">
        <h5>
          {{ $t('error-detail.actions.endTaskModal.heading') }}
        </h5>
      </div>
      <div class="modal-body">
        <textarea
          id="description"
          v-model="endDesc"
          name="description"
          autofocus
          class="description-area"
          :placeholder="$t('error-detail.actions.endTaskModal.placeholder')"
        />
      </div>
      <div class="modal-footer">
        <span
          @click="$emit('close-modal')"
        >
          {{ $t('error-detail.actions.endTaskModal.cancel') }}
        </span>
        <custom-button @click="endTask()">
          {{ $t('error-detail.actions.endTaskModal.submit') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'

export default {
  name: 'EndTaskModal',
  components: {
    CustomButton
  },
  props: {
    modalData: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      endDesc: ''
    }
  },
  created () {
    this.getOldDescription()
  },
  methods: {
    getOldDescription () {
      this.endDesc = this.modalData.machineError.description
      console.log(this.modalData.machineError)
    },
    async endTask () {
      try {
        const { data } = await this.$axios.post(`/machine-errors/${this.$route.params.id}/end?select=id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt`)
        Object.assign(this.modalData.machineError, data)
        this.$emit('close-modal')
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.end-task-modal-overlay {
  z-index: 99998;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  div.modal {
    z-index: 99999;
    width: 100%;
    max-width: 442px;
    height: 100%;
    max-height: 324px;
    background-color: $primary-white;
    padding: 40px 32px 32px 32px;

    div.modal-header {
      margin-bottom: 24px;
    }

    div.modal-body {
      textarea.description-area {
        width: 100%;
        height: 120px;
        padding: 16px;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(0, 0, 0, 0.42);
        outline: none;
        resize: none;
      }
    }

    div.modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;

      span {
        margin-right: 40px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.end-task-modal-overlay {
    div.modal {
      margin: 0 24px;
      padding: 24px;
      max-height: 352px;

      div.modal-header {
        text-align: center;
      }

      div.modal-footer {
        flex-direction: column-reverse;
        width: 100%;

        span {
          margin: 24.5px 0 0 0;
        }
      }
    }
  }
}
</style>

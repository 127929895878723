<template>
  <div class="error-detail-last-activity">
    <h5>{{ $t('error-detail.last-activity.heading') }}</h5>
    <ul class="activities-list">
      <li
        v-for="(activity, i) in lastActivity"
        :key="i"
        class="activity"
      >
        <div class="activity-row">
          <span
            class="person-badge"
            :style="{backgroundColor: randomColor(activity.adminFirstName && activity.adminLastName ? activity.adminEmail : activity.userEmail)}"
          >
            <span v-if="activity.adminFirstName && activity.adminLastName">
              {{ activity.adminFirstName[0] }}{{ activity.adminLastName[0] }}
            </span>
            <span v-else>
              {{ activity.userFirstName[0] }}{{ activity.userLastName[0] }}
            </span>
          </span>
          <div class="activity-info">
            <div
              v-if="activity.message === 'createMachineError'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.createMachineError.text') }} <span class="bold">"{{ taskIdName }}"</span>
            </div>
            <div
              v-else-if="activity.message === 'claimMachineError'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.claimMachineError.text') }}
            </div>
            <div
              v-else-if="activity.message === 'startMachineError'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.startMachineError.text') }} <span class="bold">"{{ taskIdName }}"</span>
            </div>
            <div
              v-else-if="activity.message === 'attachUserToMachineError'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.adminFirstName }} {{ activity.adminLastName }}</span> {{ $t('error-detail.last-activity.attachUserToMachineError.text') }} <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span>  {{ $t('error-detail.last-activity.attachUserToMachineError.text2') }}
            </div>
            <div
              v-else-if="activity.message === 'endMachineError'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.endMachineError.text') }}
            </div>
            <div
              v-else-if="activity.message === 'startPause'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.startPause.text') }}
            </div>
            <div
              v-else-if="activity.message === 'stopPause'"
              class="activity-info-top"
            >
              <span class="bold">{{ activity.userFirstName }} {{ activity.userLastName }}</span> {{ $t('error-detail.last-activity.stopPause.text') }}
            </div>
            <div
              v-else
              class="activity-info-top"
            >
              {{ activity.message }}
            </div>
            <div class="activity-info-bottom">
              {{ activity.date }}
            </div>
          </div>
        </div>
        <div
          v-if="!(i + 1 == lastActivity.length)"
          class="activity-border"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ErrorDetailLastActivity',
  props: {
    lastActivity: {
      required: false,
      type: Array,
      default () {
        return []
      }
    },
    taskIdName: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      colorCache: {}
    }
  },
  methods: {
    randomColor (i) {
      const r = () => Math.floor(256 * Math.random())
      return this.colorCache[i] || (this.colorCache[i] = `rgb(${r()}, ${r()}, ${r()})`)
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-detail-last-activity {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $primary-white;
  padding: 34px 32px;

  h5 {
    margin-bottom: 26px;
  }

  ul.activities-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    li.activity {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      div.activity-row {
        display: flex;

        span.person-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
          border-radius: 50%;
          color: $primary-white;
          font-weight: 500;
          font-size: 14px;
          margin-right: 12px;
        }

        div.activity-info {
          display: flex;
          flex-direction: column;

          div.activity-info-top {
            font-size: 14px;
            line-height: 20px;
            color: $primary-mediumgrey;

            span.bold {
              font-weight: 500;
              color: $primary-black;
            }
          }

          div.activity-info-bottom {
            font-size: 14px;
            line-height: 20px;
            color: $primary-grey;
          }
        }
      }

      div.activity-border {
        content: "";
        position: absolute;
        left: 20px;
        top: 48px;
        z-index: -1;
        height: calc(100% - 8px - 8px);
        min-height: 24px;
        border-left: 1px solid $primary-lightgrey;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-detail-last-activity {
    padding: 30px 24px 24px 24px;

    h5 {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-weight: 500;
      margin-bottom: 22px;
    }
  }
}
</style>

<template>
  <div
    v-if="errorData"
    class="error-detail-wrapper"
  >
    <div class="page-header">
      <div class="page-header__content">
        <div class="left">
          <img
            v-if="windowWidth > 960"
            :src="ButtonIcon"
            alt="Back"
            class="back-button"
            @click="$router.push({ name: 'Dashboard' })"
          >
          <h4>#{{ errorData.id }}-{{ errorData.machine.name }}</h4>
        </div>
      </div>
    </div>
    <div class="error-detail-content">
      <div class="aligner">
        <div class="content-left">
          <error-detail-informations
            :detail-informations="errorData"
            class="marginator"
          />
          <error-detail-last-activity
            :last-activity="errorData.log"
            :task-id-name="`#${errorData.id} - ${errorData.machine.name}`"
            class="marginator"
          />
          <error-detail-more
            :machine-id="errorData.machineId"
            class="marginator"
          />
          <error-detail-history
            :machine-id="errorData.machineId"
            class="marginator"
          />
        </div>
        <div class="content-right">
          <error-detail-actions
            :user-data="getUserData"
            :error-data="errorData"
            :task-data="taskData"
            @rerender="getErrorData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonIcon from '@/assets/arrow_button.svg'
import ErrorDetailInformations from './ErrorDetailComponents/ErrorDetailInformations.vue'
import ErrorDetailLastActivity from './ErrorDetailComponents/ErrorDetailLastActivity.vue'
import ErrorDetailMore from './ErrorDetailComponents/ErrorDetailMore.vue'
import ErrorDetailHistory from './ErrorDetailComponents/ErrorDetailHistory.vue'
import ErrorDetailActions from './ErrorDetailComponents/ErrorDetailActions.vue'

export default {
  name: 'ErrorDetail',
  components: {
    ErrorDetailInformations,
    ErrorDetailLastActivity,
    ErrorDetailMore,
    ErrorDetailHistory,
    ErrorDetailActions
  },
  data () {
    return {
      ButtonIcon,
      errorData: null,
      taskData: null,
      windowWidth: 0
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  async created () {
    await this.getErrorData()
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    async getErrorData () {
      try {
        const { data } = await this.$axios.get(`/machine-errors/${this.$route.params.id}/?select=*,machine(*),errorCategory(name,description),mainTask(*,user,activePause),tasks(*,user,activePause)`)
        this.errorData = data
        if (this.errorData.tasks.length > 0) {
          const taskData = this.errorData.tasks[this.errorData.tasks.length - 1]
          this.taskData = taskData
        }
        console.log(this.errorData)
      } catch (err) {
        console.error(err)
      }
    },
    rerender () {
      this.getErrorData()
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-detail-wrapper {
  width: 100%;
  padding-top: 72px; // because of header

  div.page-header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 225px;
    background-color: $primary-darkgrey;
    padding: 48px 166px;

    div.page-header__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1108px;
      height: 64px;

      div.left {
        display: flex;
        align-items: center;

        img.back-button {
          cursor: pointer;
          margin-right: 32px;
        }

        h4 {
          color: $primary-white;
        }
      }
    }
  }

  div.error-detail-content {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 167px 167px 167px;
    margin-top: -48px;

    div.aligner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1108px;

      div.content-left {
        display: flex;
        flex-direction: column;
        width: 633px;

        .marginator {
          margin-bottom: 24px;
        }
      }

      div.content-right {
        display: flex;
        flex-direction: column;
        width: 443px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-detail-wrapper {
    padding: 64px 0 100px 0;

    div.page-header {
      height: 72px;
      padding: 24px;

      div.page-header__content {
        max-width: 100%;
        height: unset;

        div.left {
          h4 {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }

    div.error-detail-content {
      padding: 0;
      margin-top: 0;

      div.aligner {
        flex-direction: column-reverse;
        max-width: 100%;

        div.content-left {
          width: 100%;

          .marginator {
            margin-bottom: 16px;
          }
        }

        div.content-right {
          margin-bottom: 16px;
          width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <div class="error-detail-actions">
    <ul
      class="info-part"
      :class="{'marginator': userData.role !== 'OPERATOR' && errorData.finishedAt === null}"
    >
      <li class="information">
        <img
          :src="PersonIcon"
          :alt="$t('error-detail.actions.unassigned')"
          class="status-icon"
        >
        <span
          v-if="!taskData"
          class="name-info"
        >
          {{ $t('error-detail.actions.unassigned') }}
        </span>
        <span
          v-else
          class="name-info"
        >
          {{ taskData.user.firstName }} {{ taskData.user.lastName }}
        </span>
        <span
          v-if="canShowAssignTask"
          class="assign-task"
          @click="showAssignTaskModal = true"
        >
          {{ $t('error-detail.actions.assign') }}
        </span>
        <assign-employee-modal
          v-if="showAssignTaskModal"
          :modal-data="assignTaskModalData"
          @close-modal="handleModalClose()"
        />
        <span
          v-if="canShowRemoveAssign"
          class="remove-assign"
          @click="showRemoveAssignModal = true"
        >
          {{ $t('error-detail.actions.remove-assign') }}
        </span>
      </li>
      <li
        v-if="taskData"
        class="information"
      >
        <img
          :src="DateIcon"
          :alt="$t('error-detail.actions.claimed')"
          class="status-icon"
        >
        <span class="claimed">
          {{ $t('error-detail.actions.claimed') }} {{ dateHelper(taskData.createdAt) }}, {{ timeHelper(taskData.createdAt) }}
        </span>
      </li>
      <li class="information">
        <img
          :src="SettingsIcon"
          :alt="$t('error-detail.actions.status.statusIcon')"
          class="status-icon"
        >
        <div
          v-if="errorData.mainTask === null"
          class="info-status new"
        >
          {{ $t('error-detail.actions.status.new') }}
        </div>
        <div
          v-else-if="errorData.mainTask.status === 'RUNNING' && errorData.finishedAt === null"
          class="info-status in-progress"
        >
          {{ $t('error-detail.actions.status.inProgress') }}
        </div>
        <div
          v-else-if="errorData.mainTask.status === 'PAUSED'"
          class="info-status paused"
        >
          {{ $t('error-detail.actions.status.paused') }}
        </div>
        <div
          v-else-if="errorData.finishedAt != '' || errorData.finishedAt != null"
          class="info-status finished"
        >
          {{ $t('error-detail.actions.status.finished') }}
        </div>
        <!-- TODO: neexistuje -->
        <div
          v-else-if="errorData.mainTask.status === 'DONTFIXED'"
          class="info-status"
        >
          {{ $t('error-detail.actions.status.dontFixed') }}
        </div>
        <!-- TODO: neexistuje -->
        <div
          v-else-if="errorData.mainTask.status === 'CANCELLED'"
          class="info-status"
        >
          {{ $t('error-detail.actions.status.cancelled') }}
        </div>
      </li>
    </ul>
    <!-- OPERATOR, MAINTAINER -->
    <div
      class="action-part"
    >
      <div
        v-if="errorData.finishedAt === null && userData.role !== 'OPERATOR'"
        class="buttons"
      >
        <custom-button
          v-if="errorData.canBeClaimed"
          fullwidth
          @click="claimTask"
        >
          <template v-slot:leftIcon>
            <img
              :src="ClaimTaskIcon"
              alt="Icon"
            >
          </template>
          {{ $t('error-detail.actions.claimTask') }}
        </custom-button>
        <custom-button
          v-if="!isStarted && hasCapacity && errorData.startedAt == null && !errorData.canBeClaimed && !isOldTaskVisible"
          fullwidth
          :disabled="!canStartTask"
          @click="startTask"
        >
          <template v-slot:leftIcon>
            <img
              :src="StartTaskIcon"
              alt="Icon"
            >
          </template>
          {{ $t('error-detail.actions.start') }}
        </custom-button>
        <div
          v-if="errorData.startedAt != null"
          class="pause-stop-help"
        >
          <div class="top-part">
            <custom-button
              v-if="canStartPause"
              fullwidth
              class="marginator"
              @click="showStartPauseModal = true"
            >
              <template v-slot:leftIcon>
                <img
                  :src="PauseTaskIcon"
                  alt="Icon"
                >
              </template>
              {{ $t('error-detail.actions.pause') }}
            </custom-button>
            <start-pause-modal
              v-if="showStartPauseModal"
              :modal-data="taskData"
              @close-modal="handleModalClose()"
            />
            <custom-button
              v-if="canEndTask"
              fullwidth
              type="red-custom-button"
              @click="showEndTaskModal = true"
            >
              <template v-slot:leftIcon>
                <img
                  :src="StopTaskIcon"
                  alt="Icon"
                >
              </template>
              {{ $t('error-detail.actions.stop') }}
            </custom-button>
            <end-task-modal
              v-if="showEndTaskModal"
              :modal-data="taskData"
              @close-modal="handleModalClose()"
            />
            <custom-button
              v-if="canEndPause"
              fullwidth
              @click="finishPause"
            >
              <template v-slot:leftIcon>
                <img
                  :src="PauseTaskIcon"
                  alt="Icon"
                >
              </template>
              {{ $t('error-detail.actions.finishPause') }} ({{ pauseTimer.days }}:{{ pauseTimer.hours }}:{{ pauseTimer.minutes }}:{{ pauseTimer.seconds }})
            </custom-button>
          </div>
          <p
            v-if="canCallHelp"
            class="text-line"
          >
            <span>{{ $t('error-detail.actions.or') }}</span>
          </p>
          <custom-button
            v-if="canCallHelp"
            fullwidth
            type="white-custom-button"
            class="help-button"
            @click="showStartPauseModal"
          >
            <template v-slot:leftIcon>
              <img
                :src="HelpTaskIcon"
                alt="Icon"
              >
            </template>
            {{ $t('error-detail.actions.help') }}
          </custom-button>
        </div>
        <!-- ak uz ma udrzbar zacatu inu ulohu, zobrazuje sa pokial ID ulohy !== params.id -->
        <div
          v-if="isOldTaskVisible && isMineTask && !isStarted"
          class="other-task"
        >
          <p class="other-task-text">
            {{ $t('error-detail.actions.otherTask') }}
            <span
              v-if="userOldTask"
              class="old-task"
              @click="goToOldTask()"
            >
              #{{ userOldTask.id }}-{{ userOldTask.machine.name }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ADMIN, HEAD_MAINTAINER, MAINTAINER, OPERATOR
import PersonIcon from '@/assets/icons/person.svg'
import DateIcon from '@/assets/icons/date_range.svg'
import SettingsIcon from '@/assets/icons/settings.svg'
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import ClaimTaskIcon from '@/assets/icons/compare_arrows.svg'
import StartTaskIcon from '@/assets/icons/start.svg'
import PauseTaskIcon from '@/assets/icons/pause.svg'
import StopTaskIcon from '@/assets/icons/stop.svg'
import HelpTaskIcon from '@/assets/icons/help.svg'
import StartPauseModal from '@/components/GlobalComponents/Modals/StartPauseModal.vue'
import EndTaskModal from '@/components/GlobalComponents/Modals/EndTaskModal.vue'
import AssignEmployeeModal from '@/components/GlobalComponents/Modals/AssignEmployeeModal.vue'

export default {
  name: 'ErrorDetailActions',
  components: {
    CustomButton,
    StartPauseModal,
    EndTaskModal,
    AssignEmployeeModal
  },
  props: {
    userData: {
      required: true,
      type: Object
    },
    errorData: {
      required: true,
      type: Object
    },
    taskData: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      PersonIcon,
      DateIcon,
      SettingsIcon,
      ClaimTaskIcon,
      StartTaskIcon,
      PauseTaskIcon,
      StopTaskIcon,
      HelpTaskIcon,
      claimedTaskData: null,
      startTaskData: null,
      showStartPauseModal: false,
      showEndTaskModal: false,
      pauseTimer: {
        interval: null,
        date: null,
        years: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        intervals: {
          second: 1000,
          minute: 1000 * 60,
          hour: 1000 * 60 * 60,
          day: 1000 * 60 * 60 * 24
        }
      },
      showAssignTaskModal: false,
      showRemoveAssignModal: false,
      assignTaskModalData: {
        createdAt: this.errorData.createdAt,
        machine: this.errorData.machine.name,
        status: this.errorData.machineStatus,
        id: this.errorData.machine.id
      },
      userOldTask: null,
      isOldTaskVisible: false
    }
  },
  computed: {
    isMineTask () {
      if (this.taskData != null) {
        return this.taskData.userId === this.userData.id
      } else {
        return false
      }
    },
    hasCapacity () {
      return this.userData.canClaimMachineError
    },
    isStarted () {
      if (this.taskData != null) {
        return this.taskData.machineError.startedAt != null
      } else {
        return false
      }
    },
    isPaused () {
      if (this.taskData != null) {
        return this.taskData.activePause != null && this.isStarted && !this.isFinished
      } else {
        return false
      }
    },
    isFinished () {
      return this.taskData.machineError.finishedAt != null
    },
    canStartTask () {
      return this.isMineTask && this.hasCapacity && !this.isStarted
    },
    canStartPause () {
      return this.isMineTask && !this.isPaused && !this.isFinished && this.isStarted
    },
    canEndPause () {
      return this.isMineTask && this.isPaused && !this.isFinished && this.isStarted
    },
    canEndTask () {
      return this.isMineTask && this.isStarted && !this.isPaused && !this.isFinished
    },
    canCallHelp () {
      return this.isMineTask && this.isStarted && !this.isFinished
    },
    canShowAssignTask () {
      if ((this.userData.role === 'ADMIN' || this.userData.role === 'HEAD_MAINTAINER') && this.errorData.mainTask === null) {
        return true
      } else {
        return false
      }
    },
    canShowRemoveAssign () {
      if ((this.userData.role === 'ADMIN' || this.userData.role === 'HEAD_MAINTAINER') && this.errorData.mainTask != null) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    // this.userOldTask = {}
    this.getUserOldTask()
  },
  mounted () {
    this.pauseTimer.interval = setInterval(() => {
      this.pauseTime()
    }, 1000)
  },
  destroyed () {
    clearInterval(this.pauseTimer.interval)
  },
  methods: {
    dateHelper,
    timeHelper,
    async claimTask () {
      try {
        const { data } = await this.$axios.post(`/machine-errors/${this.$route.params.id}/claim-error`)
        this.claimedTaskData = data
        console.log(this.claimedTaskData)
        this.$emit('rerender')
      } catch (err) {
        console.log(err)
      }
    },
    async startTask () {
      try {
        const response = await this.$axios.get(`/tasks/${this.$route.params.id}/?select=*,user(firstName,lastName,email),machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`)
        this.startTaskData = response.data
        console.log(this.startTaskData)
        const { data } = await this.$axios.post(`/machine-errors/${this.$route.params.id}/start?select=id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt`)
        console.log(data)
        this.startTaskData.machineError = { ...this.startTaskData.machineError, ...data }
        this.$emit('rerender')
      } catch (err) {
        console.log(err)
      }
    },
    async finishPause () {
      try {
        const { data } = await this.$axios.post(`/tasks/${this.$route.params.id}/finish-pause?select=activePause,machineError(id,name,description,log,machine(*),mainTask(id,userId),tasks(id,userId),startedAt,finishedAt)`)
        Object.assign(this.taskData, data)
      } catch (err) {
        console.error(err)
      }
    },
    pauseTime () {
      if (this.isPaused) {
        const datum = this.taskData.activePause.startAt
        const countFrom = new Date(datum).getTime()
        var now = new Date()
        var countFrom2 = new Date(countFrom)
        var timeDifference = (now - countFrom2)
        var secondsInADay = 60 * 60 * 1000 * 24
        var secondsInAHour = 60 * 60 * 1000

        this.pauseTimer.days = Math.floor(timeDifference / (secondsInADay) * 1)
        this.pauseTimer.years = Math.floor(this.pauseTimer.days / 365)
        if (this.pauseTimer.years > 1) {
          this.pauseTimer.days = this.pauseTimer.days - (this.pauseTimer.years * 365)
        }
        this.pauseTimer.hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1)
        this.pauseTimer.minutes = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1)
        this.pauseTimer.seconds = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1)
      }
    },
    handleModalClose () {
      this.showStartPauseModal = false
      this.showEndTaskModal = false
      this.showAssignTaskModal = false
      this.$emit('rerender')
    },
    async getUserOldTask () {
      try {
        const { data } = await this.$axios.get('/machine-errors/')

        for (let i = 0; i < data.length; i++) {
          if (data[i].startedAt !== null && data[i].finishedAt === null && data[i].mainTask.userId === this.userData.id) {
            this.userOldTask = data[i]
          }
        }

        if (this.userOldTask) {
          if (this.userOldTask.id !== this.$route.params.id) {
            this.isOldTaskVisible = true
          } else {
            this.isOldTaskVisible = false
          }
        }
        console.log(this.isOldTaskVisible)
      } catch (err) {
        console.error(err)
      }
    },
    goToOldTask () {
      this.$router.push({ name: 'ErrorDetailMachine', params: { id: this.userOldTask.id } })
      this.isOldTaskVisible = false
      this.$emit('rerender')
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-detail-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $primary-white;
  padding: 42px 32px 32px 32px;

  ul.info-part {
    padding: 0;
    list-style-type: none;

    &.marginator {
      margin-bottom: 42px;
    }

    li.information {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      .status-icon {
        margin-right: 16px;
      }

      span.name-info,
      span.claimed {
        font-weight: 500;
      }

      span.assign-task,
      span.remove-assign {
        cursor: pointer;
        text-decoration: underline;
        color: $primary-lightorange;
        margin-left: 16px;
      }

      div.info-status {
        display: flex;
        height: 24px;
        padding: 2px 12px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 20px;

        &.new {
          background-color: $primary-lightgrey;
          color: $primary-darkgrey;
        }

        &.in-progress {
          background-color: #1976D2;
          color: $primary-white;
        }

        &.paused {
          background-color: $status-yellow;
          color: $primary-black;
        }

        &.finished {
          background-color: $status-green;
          color: $primary-white;
        }
      }
    }
  }

  div.action-part {
    div.pause-stop-help {
      display: flex;
      flex-direction: column;

      div.top-part {
        display: flex;
        margin-bottom: 16px;

        .marginator {
          margin-right: 8px;
        }
      }

      p.text-line {
        position: relative;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $primary-lightgrey;
        line-height: 0.1em;
        margin: 10px 0;

        span {
          width: 57px;
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-color: $primary-white;
          padding: 0 12px;
          font-size: 12px;
          color: $primary-grey;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }

      .help-button {
        margin-top: 16px;
      }
    }

    div.other-task {
      p.other-task-text {
        font-weight: 500;

        span.old-task {
          cursor: pointer;
          color: $primary-lightorange;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-detail-actions {
    padding: 34px 24px 24px 24px;

    ul.info-part {
      &.marginator {
        margin-bottom: 32px;
      }
    }

    div.action-part {
      div.pause-stop-help {
        div.top-part {
          flex-direction: column;

          .marginator {
            margin: 0 0 8px 0;
          }
        }
      }
    }
  }
}
</style>
